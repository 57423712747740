import React from "react";
import Board from "./Board";
import {Footer} from "./Footer";
import {Help} from "./Help";
import Stats from "./Stats";
import {instanceOf} from 'prop-types';
import {withCookies, Cookies} from 'react-cookie';

const qs = require('query-string');

class App extends React.Component {
  static propTypes = {
    cookies: instanceOf(Cookies).isRequired
  };

  constructor(props) {
    super(props);

    const {cookies} = props;

    this.params = qs.parse(window.location.search);

    this.state = cookies.get('app.state');
    if (!this.state) {
      this.state = {
        seen_help: false
      }
      cookies.set('app.state', this.state, { expires: new Date(2050, 0, 1, 0, 0, 0) });
    }

    this.toggleHelp = this.toggleHelp.bind(this);
    this.toggleStats = this.toggleStats.bind(this);
    this.setStateAndCookie = this.setStateAndCookie.bind(this);
    this.daysSinceEpoch = this.daysSinceEpoch.bind(this);
    this.boardRef = React.createRef();
    this.helpRef = React.createRef();
    this.statsRef = React.createRef();
  }

  toggleHelp() {
    console.log("help clicked");
    this.helpRef.current.toggle();
  }

  toggleStats() {
    console.log("stats clicked: " + this.boardRef.current);
    this.statsRef.current.toggle(this.boardRef.current.getStats());
  }

  render() {
    return <div>
      <div className={"App"}>
        <header>
          <div className={"Stats Button"} onClick={this.toggleStats}>
            <img src={"chart.svg"} alt={"statistics"}/>
          </div>
          <div className={"Title"}>
            בפלה
          </div>
          <div className={"HelpBtn Button"} onClick={this.toggleHelp}>
            <img src={"help.svg"} alt={"help"}/>
          </div>
        </header>

        <Board ref={this.boardRef} index={this.daysSinceEpoch(this)}/>

        <Footer index={this.daysSinceEpoch(this)}/>
      </div>
      <Help ref={this.helpRef} />
      <Stats ref={this.statsRef} />
    </div>
  }

  componentDidMount() {
    if (!this.state.seen_help) {
      console.log("showing help for first time");

      this.toggleHelp();
      this.setStateAndCookie({seen_help: true});
    }
  }

  setStateAndCookie(obj) {
    this.setState(obj);

    const {cookies} = this.props;
    let localState = Object.assign(this.state, obj);
    cookies.set('app.state', localState, { expires: new Date(2050, 0, 1, 0, 0, 0) });

    console.log("new app state: " + JSON.stringify(this.state));
  }

  daysSinceEpoch(self) {
    const one_day = 1000 * 60 * 60 * 24;
    const epoch = new Date(2023, 1, 3);
    let now = new Date();
    now = new Date(now.getFullYear(), now.getMonth(), now.getDate());
    let days = Math.round((now.getTime() - epoch.getTime()) / one_day);
    if (self.params["custom"]) {
      return 0;
    }
    if (self.params["days-delta"] && Number.isInteger(Number(self.params["days-delta"]))) {
      let days_delta = Number(self.params["days-delta"]);
      return Math.max(days + days_delta, 0);
    }
    return days;
  }
}

export default withCookies(App);