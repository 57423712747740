import React from "react";
import './Stats.css';
import {withCookies, Cookies} from 'react-cookie';
import {instanceOf} from "prop-types";

class Stats extends React.Component {
  static propTypes = {
    cookies: instanceOf(Cookies).isRequired
  };
  stats = {
    baflas_per_game: {},
    baflas_total: 0,
    histogram: [],
    wins: 0,
    loses: 0
  }

  constructor(props) {
    super(props);

    const {cookies} = props;
    this.state = cookies.get('stats.state');
    if (!this.state) {
      this.state = {
        visibility: 'hidden'
      }
      cookies.set('stats.state', this.state, { expires: new Date(2050, 0, 1, 0, 0, 0) });
    }

    this.toggle = this.toggle.bind(this);
  }

  toggle(stats) {
    if (stats && stats.baflas_total) {
      this.stats = stats;
    }
    let toggled = this.state.visibility === 'hidden' ? 'visible' : 'hidden';
    this.setState({visibility: toggled});
  }

  render() {
    let max = Math.max(...this.stats.histogram);
    let widths;
    if (max < 10) {
      widths = this.stats.histogram.map(v => 20 + (v * 20));
    } else {
      widths = this.stats.histogram.map(v => (v / max * 90) + "%");
    }

    return <div>
      <div className={"StatsModel"} style={{visibility: this.state.visibility}}>
        <header>
          <button onClick={this.toggle}>
            <img src={"close.svg"} alt={"close"}/>
          </button>
          <div className={"StatsTitle"}>סטטיסטיקה</div>
        </header>
        <div>צברת {this.stats.baflas_total} בפלות</div>
        <div>נצחונות {this.stats.wins}, הפסדים {this.stats.loses}</div>
        <hr />
        <div>התפלגות נצחונות</div>
        <div className={"Row"}><div className={"Index"}>0</div><div className={"Bar"} style={{width: widths[0]}}>{this.stats.histogram[0] || 0}</div></div>
        <div className={"Row"}><div className={"Index"}>1</div><div className={"Bar"} style={{width: widths[1]}}>{this.stats.histogram[1] || 0}</div></div>
        <div className={"Row"}><div className={"Index"}>2</div><div className={"Bar"} style={{width: widths[2]}}>{this.stats.histogram[2] || 0}</div></div>
        <div className={"Row"}><div className={"Index"}>3</div><div className={"Bar"} style={{width: widths[3]}}>{this.stats.histogram[3] || 0}</div></div>
        <div className={"Row"}><div className={"Index"}>4</div><div className={"Bar"} style={{width: widths[4]}}>{this.stats.histogram[4] || 0}</div></div>
        <div className={"Row"}><div className={"Index"}>5</div><div className={"Bar"} style={{width: widths[5]}}>{this.stats.histogram[5] || 0}</div></div>
      </div>
    </div>
  }
}

export default withCookies(Stats);