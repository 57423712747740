import React from "react";
import './Help.css';

export class Help extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      visibility: 'hidden'
    }

    this.toggle = this.toggle.bind(this);
  }

  toggle() {
    let toggled = this.state.visibility === 'hidden' ? 'visible' : 'hidden';
    this.setState({visibility: toggled});
  }

  render() {
    return <div>
      <div className={"Help"} style={{visibility: this.state.visibility}}>
        <header>
          <button onClick={this.toggle}>
            <img src={"close.svg"} alt={"close"}/>
          </button>
          <div className={"HelpTitle"}>הוראות</div>
        </header>
        <div className={"Content"}>
          <div>הבפלה מורכבת משש מילים - 3 לאורך ו-3 לרוחב</div>
          <div>כל יום יש בפלה חדשה</div>
          <hr />
          <div>גררו אות מעל <b>כל</b> אות אחרת בבפלה כדי להחליף בינהן</div>
          <div>החליפו אותיות כדי לסדר את המילים המבולגנות</div>
          <hr />
          <div>אות ירוקה נמצאת במקום הנכון</div>
          <div className={"Example"} style={{height: 50}}>
            <div className="Tile green" style={{right: 20}}>ד</div>
            <div className="Tile grey" style={{right:  80}}>ו</div>
            <div className="Tile grey" style={{right: 140}}>ג</div>
            <div className="Tile grey" style={{right: 200}}>מ</div>
            <div className="Tile grey" style={{right: 260}}>ה</div>
          </div>
          <div>אות צהובה נמצאת במילה הנכונה, אבל לא במקום הנכון</div>
          <div className={"Example"} style={{height: 50}}>
            <div className="Tile grey" style={{right: 20}}>ד</div>
            <div className="Tile grey" style={{right: 80}}>ו</div>
            <div className="Tile yellow" style={{right: 140}}>ג</div>
            <div className="Tile grey" style={{right: 200}}>מ</div>
            <div className="Tile grey" style={{right: 260}}>ה</div>
          </div>
          <div>אות אפורה לא נמצאת במילה הנכונה</div>
          <div className={"Example"} style={{height: 50}}>
            <div className="Tile grey" style={{right: 20}}>ד</div>
            <div className="Tile grey" style={{right: 80}}>ו</div>
            <div className="Tile grey" style={{right: 140}}>ג</div>
            <div className="Tile grey" style={{right: 200}}>מ</div>
            <div className="Tile grey" style={{right: 260}}>ה</div>
          </div>
          <div>אות צהובה שנמצאת על צומת יכולה להשתייך לכל אחת מהמילים שבהן היא נמצאת</div>
          <div className={"Example"} style={{height: 250}}>
            <div style={{height: 60}}>
              <div className="Tile grey" style={{right: 260}}>ב</div>
            </div>
            <div style={{height: 60}}>
              <div className="Tile grey" style={{right: 260}}>פ</div>
            </div>
            <div style={{height: 60}}>
              <div className="Tile grey" style={{right: 260}}>ל</div>
            </div>
            <div style={{height: 60}}>
              <div className="Tile grey" style={{right: 20}}>ד</div>
              <div className="Tile grey" style={{right: 80}}>ו</div>
              <div className="Tile grey" style={{right: 140}}>ג</div>
              <div className="Tile grey" style={{right: 200}}>מ</div>
              <div className="Tile yellow" style={{right: 260}}>ה</div>
            </div>
          </div>
          <hr />
          <div>נסו לפתור את הבפלה תוך 15 החלפות או פחות</div>
          <div>כל בפלה ניתן לפתור ב-10 החלפות</div>
          <div>צברו בפלות לפי מספר ההחלפות שלא ניצלתם</div>
        </div>
      </div>
    </div>
  }
}