import React from 'react';
import Tile from './Tile';
import './Board.css';
import {Swappable, Plugins} from "@shopify/draggable";
import {instanceOf} from "prop-types";
import {withCookies, Cookies} from 'react-cookie';

let games = require('./puzzles.json')
const qs = require('query-string');

class Board extends React.Component {
  static propTypes = {
    cookies: instanceOf(Cookies).isRequired
  };

  constructor(props) {
    super(props);

    const {cookies} = props;

    this.params = qs.parse(window.location.search);

    const ORIG_STATE = {
      game_index: -1,
      letters: [],
      solution: [],
      gameOver: false,
      success: false,
      remainingMoves: 15,
      width: window.innerWidth,
      stats: {
        baflas_per_game: {},
        baflas_total: 0,
        histogram: [0, 0, 0, 0, 0, 0],
        wins: 0,
        loses: 0
      }
    };

    const FRESH_GAME_STATE = {
      game_index: props.index,
      letters: [],
      solution: [],
      gameOver: false,
      success: false,
      remainingMoves: 15,
      width: window.innerWidth
    };

    this.state = Object.assign({}, ORIG_STATE, cookies.get('board.state') || {});
    console.log("custom: " + this.params["custom"]);
    if (this.params["custom"]) {
      let parts = String(this.params["custom"]).split("|");
      this.state.game_index = 0;
      this.state.game = [parts[0].split("").reduce((acc, char) => char + acc, ""), parts[1]];
      this.state.solutionCheat = false;
      this.populateLettersArray(this.state.letters, this.state.game[1]);
      this.populateLettersArray(this.state.solution, this.state.game[0]);
    } else if (staleState(this)) {
      this.state = Object.assign(this.state, FRESH_GAME_STATE);
      this.state.game = games[this.state.game_index % games.length];
      this.state.solutionCheat = this.params["show-solution"];
      this.populateLettersArray(this.state.letters, this.state.game[1]);
      this.populateLettersArray(this.state.solution, this.state.game[0]);

      console.log("state reset: " + JSON.stringify(this.state));
      cookies.set('board.state', this.state, { expires: new Date(2050, 0, 1, 0, 0, 0) });
    }

    this.canSwap = this.canSwap.bind(this);
    this.swapTiles = this.swapTiles.bind(this);
    this.setupSwappable = this.setupSwappable.bind(this);
    this.updateAnalytics = this.updateAnalytics.bind(this);
    this.onFailed = this.onFailed.bind(this);
    this.onWon = this.onWon.bind(this);
    this.tileSize = this.tileSize.bind(this);
    this.setStateAndCookie = this.setStateAndCookie.bind(this);
    this.setStateAndCookie = this.setStateAndCookie.bind(this);
    this.getStats = this.getStats.bind(this);
    this.initiateTimer = this.initiateTimer.bind(this);
    this.shareScore = this.shareScore.bind(this);
    this.scrollToBoard = this.scrollToBoard.bind(this);

    this.setup = false;

    function staleState(self) {
      const requestedGameIndex = props.index;
      const previousGameIndex = self.state.game_index;
      return (requestedGameIndex !== previousGameIndex);
    }
  }

  onMoved() {
    const remaining = this.state.remainingMoves - 1;
    this.setStateAndCookie({remainingMoves: remaining});
    console.log("moved: " + remaining);
    return remaining;
  }

  canSwap(item) {
    return !this.state.gameOver && item != null && item.color !== 'green';
  }

  setupSwappable() {
    if (this.setup) return;
    let grid = document.querySelectorAll('.Grid');
    if (grid.length === 0) {
      return;
    }

    const swappable = new Swappable(grid, {
      draggable: '.Tile',
      delay: 0,
      plugins: [Plugins.SwapAnimation],
      swapAnimation: {
        duration: 200,
        easingFunction: 'ease-in-out',
        horizontal: true
      },
    });
    let overItem = null;
    let srcItem = null;
    function itemOfNode(node) {
      return {
        row: node.getAttribute("data-row"),
        col: node.getAttribute("data-col"),
        color: node.getAttribute("data-color"),
        letter: node.innerHTML
      };
    }
    swappable.on('drag:start', (e) => {
      srcItem = itemOfNode(e.originalSource);
      if (!this.canSwap(srcItem)) {
        e.cancel();
        srcItem = null;
      }
    });
    swappable.on('drag:over', (e) => {
      overItem = itemOfNode(e.over);
      if (overItem.row === srcItem.row && overItem.col === srcItem.col) {
        overItem = null;
      }
    });
    swappable.on('drag:out', (e) => {
      overItem = null;
    });
    swappable.on('drag:stop', () => {
      if (this.canSwap(srcItem) && this.canSwap(overItem) && srcItem.letter !== overItem.letter) {
        this.swapTiles(srcItem, overItem);
      }
      overItem = null;
      srcItem = null;
    });

    this.setup = true;
  }

  populateLettersArray(arr, str) {
    let index = 0;
    for (let row = 0; row < 5; row++) {
      arr[row] = [];
      for (let col = 0; col < 5; col++) {
        if (this.shouldSkip([row, col])) {
          arr[row].push("_");
          continue;
        }
        arr[row].push(str.charAt(index));
        index++;
      }
    }
  }

  shouldSkip([j, i]) {
    return (j === 1 && i === 1) ||
        (j === 1 && i === 3) ||
        (j === 3 && i === 1) ||
        (j === 3 && i === 3);
  }

  getTileColor(letters, row, col) {
    if (this.state.gameOver) {
      if (this.state.success) {
        return "happy";
      } else {
        return "sad";
      }
    }
    const solution = this.state.solution;
    const letter = letters[row][col];

    function alreadyGreen(row, col) {
      return letters[row][col] === solution[row][col];
    }

    function inSameRow(letter, row) {
      if ([0, 2, 4].includes(row)) {
        for (let i = 0; i < 5; i++) {
          if (letter === solution[row][i]) {
            if (!alreadyGreen(row, i)) {
              return true;
            }
          }
        }
      }
      return false;
    }

    function inSameCol(letter, col) {
      if ([0, 2, 4].includes(col)) {
        for (let i = 0; i < 5; i++) {
          if (letter === solution[i][col]) {
            if (!alreadyGreen(i, col)) {
              return true;
            }
          }
        }
      }
      return false;
    }

    if (letter === solution[row][col]) {
      return "green";
    } else if (inSameRow(letter, row) || inSameCol(letter, col)) {
      return "yellow"
    } else {
      return "grey";
    }
  }

  swapTiles(from, to) {
    console.log("swap tiles called: " + JSON.stringify(from) + " => " + JSON.stringify(to) + ", letters: " + this.state);

    const copyLetters = [...this.state.letters];
    const fromLetter = from.letter;
    copyLetters[from.row][from.col] = copyLetters[to.row][to.col];
    copyLetters[to.row][to.col] = fromLetter;

    this.setStateAndCookie({letters: copyLetters});

    let remaining = this.onMoved();

    if (this.hasWon()) {
      this.onWon();
    } else if (remaining === 0) {
      this.onFailed();
    }
  }

  hasWon() {
    return this.state.letters.join("") === (this.state.solution.join(""));
  }

  tileSize() {
    if (window.innerWidth <= 350) {
      return 43;
    } else if (window.innerWidth <= 500) {
      return 65;
    } else {
      return 85;
    }
  }

  initiateTimer() {
    let target = new Date();
    target.setHours(24, 0, 0, 0);
    target.getTime();

    this.timer = setInterval(function() {
      if (this.timer) {
        clearInterval(this.timer);
      }
      let now = new Date().getTime();
      let distance = target - now;

      function pad(num) {
        num = Math.floor(num);
        if (num < 10) return "0" + num;
        return num;
      }

      let hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      let minutes = pad((distance % (1000 * 60 * 60)) / (1000 * 60));
      let seconds = pad((distance % (1000 * 60)) / 1000);

      document.getElementById("TimerTime").innerHTML = hours + ":" + minutes + ":" + seconds;

      if (distance < 0) {
        clearInterval(this.timer);
        window.location.reload();
      }
    }, 1000);
  }

  updateAnalytics(success, baflas) {
    let dataLayer = window.dataLayer || [];
    const obj = {'game_won': success};
    if (success) {
      obj['baflas'] = baflas;
    }
    dataLayer.push(obj);
    dataLayer.push({'event': 'game_ended'});
    dataLayer.push({'event': success ? 'game_won' : 'game_lost'});
  }

  render() {

    let letters = this.state.letters;

    const tileSize = this.tileSize();
    let elements = this.tilesJSX(letters, tileSize);

    let solution;
    if ((this.state.gameOver && !this.state.success) || this.state.solutionCheat) {
      let solution_elements = this.tilesJSX(this.state.solution, tileSize / 2);
      solution = <div className={"Solution"}>
        <div className={"Title"}>פתרון</div>
        <div className={"Grid"}>{solution_elements}</div>
      </div>
    } else {
      solution = <div></div>
    }

    let moves = `${this.state.remainingMoves} החלפות נותרו`
    let baflas = null;
    if (this.state.gameOver) {
      if (this.state.success) {
        if (this.state.remainingMoves > 0) {
          let award = Math.min(this.state.remainingMoves, 5);
          if (award > 1) {
            moves = `זכית ב-${award} בפלות! 😊`
          } else {
            moves = `זכית בבפלה אחת 😅`
          }
          baflas = [];
          for (let i = 0; i < award; i++) {
            baflas.push(<img alt='בפלה' src={'bafla.png'}/>);
          }
        } else {
          moves = `וואו, הצלחת ברגע האחרון`
        }
      } else {
        moves = `לא נותרו החלפות 😔`
      }
    } else if (this.state.remainingMoves === 1) {
      moves = `נותרה החלפה אחרונה`
    }

    let share;
    if (this.state.gameOver) {
      let text = this.state.success ? "שתפו את ההישג!" : "שתפו את הכישלון 😉";
      share = <div className={"Share"} id={"Share"} onClick={this.shareScore}>
        <div>{text}</div>
        <img src={"share.svg"} alt={"share"} />
      </div>
    }

    let timer;
    if (this.state.gameOver) {
      timer = <div className={"Timer"}>
        <div className={"TimerTitle"}>בפלה חדשה בעוד</div>
        <div id={"TimerTime"}>_</div>
      </div>

      this.initiateTimer();
    } else {
      timer = <div></div>
    }

    return <div className="Board">
      <div className={"Grid"}>{elements}</div>
      <div className={"Moves"}>
        {moves}
        {baflas ? <div className={"Baflas"}>{baflas}</div> : ""}
      </div>
      {solution}
      {share}
      <div className={"Announce"}>
        <b><big>חדש!</big></b><br />
        מצב אפל (דארק מוד)<br />
        מופעל אוטומטית לפי הגדרות הדפדפן
      </div>
      <div id={"Copied"} style={{display: "none"}}>הועתק ללוח, לכו שתפו איפשהו</div>
      {timer}
    </div>
  }

  tilesJSX(letters, tileSize) {
    let elements = [];
    for (let row = 0; row < 5; row++) {
      for (let col = 0; col < 5; col++) {
        if (this.shouldSkip([row, col])) {
          continue;
        }
        elements.push(<Tile
            key={`"tile-" + ${row} + "-" + ${col}`}
            row={row}
            col={col}
            letter={letters[row][col]}
            color={this.getTileColor(letters, row, col)}
            style={{
              left: `${(4 - col) * tileSize}px`,
              top: `${row * tileSize}px`
            }}
        />);
      }
    }
    return elements;
  }

  shareScore() {
    let bafla_id = this.state.game_index + "#";
    let text;
    let url = "www.bafla.app";
    let award = Math.min(this.state.remainingMoves, 5);

    function emoji(i) {
      return (award >= i ? "⭐️" : "⬜️");
    }

    if (this.state.success) {
      if (this.state.remainingMoves > 0) {
        if (award > 0) {
          text = "פתרתי את בפלה " + bafla_id + "! 🎉\n" +
              (award > 1 ? "זכיתי ב-" + award + " בפלות 🥳" : "זכיתי בבפלה אחת 😅") +
              "\n" +
              "🟩🟩🟩🟩🟩\n" +
              "🟩" + emoji(2) + "🟩" + emoji(1) + "🟩\n" +
              "🟩🟩" + emoji(5) + "🟩🟩\n" +
              "🟩" + emoji(4) + "🟩" + emoji(3) + "🟩\n" +
              "🟩🟩🟩🟩🟩\n";
        }
      } else {
        text = "פתרתי את בפלה " + bafla_id + "! 🎉\n" +
            "אבל לא קיבלתי בפלות הפעם 😔" +
            "\n" +
            "🟩🟩🟩🟩🟩\n🟩⬜️🟩⬜🟩\n🟩🟩🟩🟩🟩\n🟩⬜🟩⬜🟩\n🟩🟩🟩🟩🟩\n";
      }
    } else {
      text = "לא פתרתי את בפלה " + bafla_id + "! 😔\n" +
          "\n" +
          "⬛️️️⬛️️️⬛️️️⬛️️⬛️️️\n⬛️️️⬜️⬛️️️⬜⬛️️️\n⬛️️️⬛️️️⬛️️️⬛️️⬛️️️\n⬛️️️⬜️⬛️️️⬜⬛️️\n⬛️️️⬛️️️⬛️️️⬛️️⬛️️️\n";
    }

    if (navigator.share === undefined) {
      console.log("copy to clipboard");
      if (navigator.clipboard) {
        navigator.clipboard.writeText(text + "\n" + url);
        let copied_text = document.getElementById("Copied");
        copied_text.style.display = "block";
        setTimeout(() => { copied_text.style.display = "none" }, 5000);

        window.dataLayer.push({
          'event': 'game_shared',
          'share_method' : 'clipboard',
          'game_won': this.state.success,
          'remaining_moves': this.state.remainingMoves
        });
      }
      return;
    }

    try {
      let data = { text: text + "\n" + url };
      if (navigator.canShare && navigator.canShare(data)) {
        navigator.share(data)
            .then(() => {
              console.info("shared");
              window.dataLayer.push({
                'event': 'game_shared',
                'share_method' : 'navigator',
                'game_won': this.state.success,
                'remaining_moves': this.state.remainingMoves
              });
            })
            .catch((error) => console.error("share failed", error));
      } else {
        console.error("can't share in this browser: " + navigator.canShare(data));
      }
    } catch (error) {
      console.error("share failed", error);
    }
  }

  componentDidMount() {
    if (document.readyState === 'complete') {
      this.setupSwappable();
    } else {
      window.onload = this.setupSwappable;
    }
    window.addEventListener('resize', () => { this.setStateAndCookie({width: window.innerWidth}) });
  }

  componentWillUnmount() {
    window.removeEventListener('resize', () => { this.setStateAndCookie({width: window.innerWidth}) });
  }

  scrollToBoard() {
    setTimeout(() => {
      let elem = document.getElementById("Share");
      console.log("scroll to view: " + elem);
      elem.scrollIntoView({ behavior: 'smooth' });
    }, 1000);
  }

  onFailed() {
    console.log("onFailed");
    let state = Object.assign(this.state, {gameOver: true, success: false});
    state.stats.baflas_per_game[state.game_index] = -1;
    state.stats.loses = state.stats.loses + 1;
    this.setStateAndCookie(state);
    this.updateAnalytics(false, 0);
    this.scrollToBoard();
  }

  onWon() {
    console.log("onWon");
    let state = Object.assign(this.state, {gameOver: true, success: true});
    const baflas = Math.min(state.remainingMoves, 5);
    state.stats.baflas_per_game[state.game_index] = baflas;
    state.stats.baflas_total += baflas;
    state.stats.histogram[baflas] = state.stats.histogram[baflas] + 1;
    state.stats.wins = state.stats.wins + 1;
    this.setStateAndCookie(state);
    this.updateAnalytics(true, state.remainingMoves);
    this.scrollToBoard();
  }

  setStateAndCookie(obj) {
    this.setState(obj);

    const {cookies} = this.props;
    let localState = Object.assign(this.state, obj);
    cookies.set('board.state', localState, { expires: new Date(2050, 0, 1, 0, 0, 0) });

    console.log("new board state: " + JSON.stringify(this.state));
  }

  getStats() {
    return this.state.stats;
  }
}

export default withCookies(Board);