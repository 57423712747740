import React from 'react'

/**
 * A Tile with a single letter
 */
export default function Tile({row, col, color, style, letter}) {

  return (
      <div
          className={"Tile " + color + " tile-" + row + "-" + col}
          data-row={row}
          data-col={col}
          data-color={color}
          style={style}>
        {letter}
      </div>
  )
}