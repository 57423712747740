import React from "react";

let gamesCount = require('./puzzles.json').length;

export class Footer extends React.Component {
  constructor(props) {
    super(props);
    this.gameIndex = props.index;
  }

  render() {
    return <footer>
      <div className={"GameIndex" + (this.gameIndex >= gamesCount ? " GameIndexOverflow" : "")}>
        בפלה {this.gameIndex}#
      </div>
      <div>
        מבוסס על
        &nbsp;
        <a href={"https://wafflegame.net/"} target={"_blank"}
           rel="noreferrer">waffle</a>
      </div>
      <div>
        <a href={"https://twitter.com/bafla_game"} target={"_blank"}
           rel="noreferrer">בפלה בטוויטר</a>
      </div>
      <div>
        עושה שימוש ב
        <a href={"https://he.wiktionary.org/"} target={"_blank"}
           rel="noreferrer">ויקימילון</a>
      </div>
    </footer>;
  }
}